<template>
  <div>
    <v-row align="center" justify="center" style="margin: auto">
      <v-col cols="auto" class="pa-0">
        <v-img
          :src="getImageWithSize"
          eager
          class="clickable circle-radius image-contain"
          @click="$emit('click')"
          ><template v-slot:placeholder>
            <v-skeleton-loader class="rounded" type="image"></v-skeleton-loader>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="auto" class="py-0 px-6">
        <div class="span-div">
          {{ category.name[selectedLocale.lcid] }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    category: Object,
    selectedLocale: Object,
  },
  computed: {
    ...mapGetters("business/", ["items"]),
    getImageWithSize() {
      const image = this.items
        .filter((i) => i.category === this.category.uuid)
        .map((i) => i.imageUrlSmall)
        .find((i) => i !== undefined);
      if (image) {
        return image;
      } else {
        return "/add.jpg";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blur {
  filter: blur(1px);
}

.circle-radius {
  position: relative;
  border-radius: 10%;
  padding: 0.2rem;
  height: 15rem;
  width: 15rem;
  box-shadow: -1px -1px 2px hsla(0, 0%, 100%, 0.3),
    1px 1px 2px rgba(0, 0, 0, 0.178), inset -1px -1px 2px rgba(0, 0, 0, 0.178),
    inset 1px 1px 2px hsla(0, 0%, 100%, 0.3);
  transition: 1s ease;
}

.image-contain {
  object-fit: cover;
}
.span-div {
  font-family: "Advent Pro", sans-serif;
  font-size: 1.4rem;
  color: #064549;
  text-shadow: -0.5px 0.5px 0px #02464963;
  text-transform: capitalize !important;
  letter-spacing: 1px;
  text-align: center;
}
h2 {
  font-family: "Helvetica";
  color: #064549;
  font-size: 1.5rem;
  font-weight: bold;
}

.clickable:hover {
  cursor: pointer;
}
</style>
