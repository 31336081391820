<template>
  <div id="open-item" style="padding:1em">
    <v-row justify="space-between" align="baseline" class="px-0">
      <v-col
        v-for="category in categoriesWithItem"
        :key="category.uuid.toString()"
        cols="6"
        md="4"
      >
        <OpenCategoryCircleMini
          class="rounded"
          @click="goToCategory(category)"
          :category="category"
          :selectedLocale="language"
        ></OpenCategoryCircleMini>
      </v-col>
    </v-row>

    <!--     <MenuDialog @close="closeMenuDialog">
      <template v-slot:activator="{ on, attrs }">
        <div class="day-menu-dialog-button-wrapper">
          <v-btn
            class="day-menu-dialog-button"
            :class="{ 'day-menu-dialog-button-close': dayMenuDialog }"
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openMenuDialog()"
          >
            <span>day's menu</span>
            <v-icon right>fas fa-utensils</v-icon>
          </v-btn>
        </div>
      </template>
    </MenuDialog> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OpenCategoryCircleMini from "@/components/openItem/OpenCategoryCircleMini";

export default {
  components: { OpenCategoryCircleMini },

  mounted() {
    this.$store.commit("system/setBreadcrumbs", [
      {
        icon: "fas fa-home",
        to: "/"
      },
      {
        text: this.$t("application.pages.menu.title")
      }
    ]);
  },
  computed: {
    ...mapGetters("business/", ["infos", "items", "categories"]),
    ...mapGetters("system/", ["language"]),
    ...mapGetters("loader/", ["loading"]),
    ...mapGetters("system/", ["dayMenuDialog"]),
    categoriesWithItem() {
      if (this.categories) {
        return this.categories.filter(
          cat => this.itemsWithCategory(cat.uuid).length > 0
        );
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      showMenuDialog: false
    };
  },
  methods: {
    itemsWithCategory(uuid) {
      return this.items
        .filter(m => m.category === uuid)
        .filter(m => m.available);
    },
    goToCategory(category) {
      this.$router.push(`/menu/${category.name[this.language.lcid]}`);
    },
    closeMenuDialog() {
      this.$store.commit("system/setMenuDialog", false);
    },
    openMenuDialog() {
      this.$store.commit("system/setMenuDialog", true);
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: grey;
  text-transform: capitalize;
  font-size: 2rem;
}
.day-menu-dialog-button-wrapper {
  position: relative;
  .day-menu-dialog-button {
    filter: contrast(1);
    position: fixed;
    z-index: 11;
    bottom: 0;
    left: 3rem;
    transition: 0.3s;
  }
  .day-menu-dialog-button-close {
    position: fixed;
    background: white !important;
    width: 10px;
    height: 10px;
    left: 50%;
    bottom: 50%;
    transform: translat(-50%, 00%);

    border-radius: 0;
  }
}
</style>
